import React from "react";
import Input from "app/components/Input";
import { Formik, Form } from "formik";
import Alert from "app/components/Alert";
import { P1 } from "app/components/Typography";
import { useGetUserProfile } from "hooks/User";
import { useSetUsername } from "../hooks/useSetUsername";
import ButtonWithLoader from "./ButtonWithLoader";
import { InputWrapper, Label, FlexLeft, SectionWrapper } from "./styles";

export function UsernameInfo() {
  const { profileData, loading: profileLoading } = useGetUserProfile();

  const [isSuccess, setIsSuccess] = React.useState(false);

  const {
    setUsername,
    loading: setUsernameLoading,
    error,
    reset,
  } = useSetUsername();

  const loading = setUsernameLoading || profileLoading;

  const handleReset = () => {
    reset();
    setIsSuccess(false);
  };

  const handleSubmit = async (username: string) => {
    handleReset();
    try {
      await setUsername(username);
      setIsSuccess(true);
    } catch {
      // Error is handled by onError callback
    }
  };

  return (
    <SectionWrapper>
      <Formik
        initialValues={{
          username: profileData?.username || "",
        }}
        enableReinitialize
        onSubmit={values => handleSubmit(values.username)}
      >
        {({ touched, handleChange, values, isValid }) => (
          <Form>
            <Label>Username</Label>
            <P1 color="monochrome.6" mb={3}>
              Your username will be visible to others. It can only contain
              letters, numbers, and underscores.
            </P1>
            <InputWrapper>
              <Input
                id="username"
                name="username"
                type="text"
                placeholder="Username"
                maxLength="24"
                onChange={handleChange}
                errors={error ? { username: error.message } : undefined}
                touched={touched}
                validate={(value: string) => {
                  if (!value || value.length < 1) {
                    return "Username cannot be blank.";
                  }
                  if (!/^[a-zA-Z0-9_]+$/.test(value)) {
                    return "Username can only contain letters, numbers, and underscores.";
                  }
                  if (value.length > 24) {
                    return "Username cannot be longer than 24 characters.";
                  }
                  return null;
                }}
              />
            </InputWrapper>
            {isSuccess && !error && (
              <Alert variant="success" closeAlert={handleReset}>
                Successfully updated username.
              </Alert>
            )}
            <FlexLeft>
              <ButtonWithLoader
                width="251px"
                height="32px"
                disabled={values.username === profileData?.username || !isValid}
                loading={loading}
                type="submit"
              >
                Update Username
              </ButtonWithLoader>
            </FlexLeft>
          </Form>
        )}
      </Formik>
    </SectionWrapper>
  );
}
